@import "./utilities/variables.scss";

.footer {
    margin-top: 0px;
    width: 100%;
    background-color: $red-dark;
    height: 250px;
    color: $white;

    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;

    padding: 0 50px;

    .left {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        text-align: center;
        .title {
            margin-bottom: 20px;
        }
        .icons {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .row {
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;
                p {
                    margin-left: 10px;
                }
            }
            .icon {
                width: 30px;
                height: 30px;
            }
            a,
            a:hover,
            a:focus,
            a:active {
                text-decoration: none;
                color: inherit;
                cursor: pointer;
            }
            svg {
                color: white;
                cursor: pointer;
            }
        }

        @media screen and (max-width: 1000px) {
            // display: none;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    .center {
        width: 50%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;

        h2 {
            margin-bottom: 20px;
        }
        h5 {
            margin-top: 20px;
        }
        ul {
            list-style: none;
            width: 90%;
            svg {
                // margin-right: 30px;
                min-height: 16px;
                min-width: 16px;
            }
            li {
                display: flex;
                margin-bottom: 10px;
                letter-spacing: 0.5px;
            }

            .textCenter {
                padding-left: 30px;
            }

            span {
                font-weight: bold;
            }
        }

        @media screen and (max-width: 428px) {
            width: 100%;
            ul {
                list-style: none;
                width: 100%;
            }
            margin-bottom: 10px;
        }
    }

    .membreteLogo {
        width: 200px;
        height: 200px;
    }

    .sello {
        background-color: $white;
        border-radius: 50%;
        @media screen and (max-width: 428px) {
            margin-bottom: 50px;
        }
    }

    @media screen and (max-width: 428px) {
        flex-direction: column;
        height: 100%;
    }
}
