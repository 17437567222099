.mapas {
    display: flex;
    flex-direction: column;
}

.rowMapa {
    margin: 20px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: 428px) {
        flex-direction: column;
    }
}

.mapa {
    display: flex;
    flex-direction: column;
    margin: 20px;
    p {
        margin-bottom: 30px;
    }
}

h3 {
    padding: 15px;
}
