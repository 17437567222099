@import "./utilities/variables.scss";

.contacto {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    form {
        font-size: medium;
        min-width: 30%;
        max-width: 80%;
        margin: 0 auto;
        padding: 1em;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        input,
        textarea {
            width: 100%;
            padding: 15px;
            margin: 10px;
        }
        input{
            height: 30px;
        }
        button {
            width: 100%;
            padding: 10px;
            margin: 10px;
            background-color: $blue-dark;
            color: $white;
            cursor: pointer;
            border: none;
        }
    }
}
