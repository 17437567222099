@import "./utilities/variables.scss";

.hero {
    display: flex;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    // background: white;
    padding: 0;
    padding-bottom: -20px;
    margin-bottom: -20px;
    margin: 0;
    // border: 2px solid red;
    @media screen and (max-width: 428px) {
        position: relative;
        margin-top: $header-height;
        height: 250;
        max-height: 250px;
    }

    .slider {
        position: relative;
        // border: 1px solid blue;
        width: 100%;
        height: 100vh;
        max-height: 100vh;
        min-height: 100vh;

        div {
            img {
                width: 100%;
                max-height: 600px;
            }
        }

        @media screen and (max-width: 960px) {
            position: relative;
            div {
                img {
                    width: 100%;
                    max-height: 600px;
                }
            }
        }

        @media screen and (max-width: 428px) {
            position: relative;
            div {
                img {
                    width: 100%;
                    height: 210px;
                }
            }
        }
    }
}
