// Colores SCSS
$purple-dark: #6150a0;
$purple-light: #6d6bb0;

$red-dark: #e25c68;
$red-light: #e64e55;

$yellow-dark: #edb040;
$yellow-light: #f6c550;

$green-dark: #418e52;
$green-light: #a6c858;

$blue-dark: #1a4688;
$blue-light: #50ace6;

$grey-dark: #646c7c;
$grey-light: #a3b4c4;

$black: #000000;
$white: #ffffff;

// TAMAÑOS SCSS
$header-height: 120px;
$header-phone-heigth: 130px;

$heigth-paralax: 350px;
