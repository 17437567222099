@import "./utilities/variables.scss";

.fullContent {
    color: $grey-dark;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .content {
        width: 100%;
        font-size: large;
        padding: 0;

        h2 {
            margin-bottom: 20px;
        }

        div {
            // margin-bottom: 30px;
        }

        .intro {
            p {
                @media screen and (max-width: 428px) {
                    font-size: 20px;
                    margin-bottom: 40px;
                }
            }
        }
    }
}

.segundaIntro {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
        width: 50%;
    }

    p {
        font-size: 20px;
        width: 40%;
    }

    @media screen and (max-width: 428px) {
        flex-direction: column;
        img {
            width: 90%;
        }

        p {
            font-size: 20px;
            width: 90%;
            margin-bottom: 20px;
        }
    }
}

.novedades {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    margin-top: 20px;

    .title {
        margin-left: 50px;
    }
    .parrafo {
        margin: 20px 50px;
    }
}

.contentRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: left;
    width: 100%;
    margin-bottom: 0;

    img {
        width: 20%;
    }
    p {
        margin: 30px;
    }

    @media screen and (max-width: 428px) {
        flex-direction: column;
        img {
            width: 100%;
        }
        p {
            margin: 0px;
            margin-bottom: 20px;
        }
    }
}
.titi {
    background-color: $blue-dark;
    color: white;
    width: 100%;
    padding: 50px;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}

.illa {
    background-color: $yellow-light;
    color: $blue-dark;
    width: 100%;
    padding: 50px;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.longImg {
    width: 1000px;
    img {
        height: 200px;
    }
}

.end {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 20px;
}

.icon {
    cursor: pointer;
}
