@import "./utilities/variables.scss";

.conocenos {
    color: $grey-dark;
    color: $grey-dark;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // border: 1px solid green;

    // @media screen and (max-width: 960px) {
    //     height: 400px;
    // }

    // @media screen and (max-width: 428px) {
    //     height: 210px;
    // }
}

.bannerConocenos {
    display: block;
    width: 100%;
    height: 98vh;
    max-height: 98vh;
    min-height: 98vh;
    overflow: hidden;
    position: relative;
    // background: white;
    padding: 0;
    padding-bottom: -20px;
    margin: -10px;
    // border: 2px solid red;
    @media screen and (max-width: 960px) {
        position: relative;
        max-height: 450px;
    }
    @media screen and (max-width: 428px) {
        position: relative;
        height: 210px;
    }

    img {
        width: 100%;
        height: 100%;
    }
}

.content {
    margin: 0px !important;
    padding: 0px;
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .intro {
        // border: 1px solid blue;
        padding: 2px !important;
        margin-bottom: 2px !important;
        margin-top: 40px !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        // font-family: "museo5";
        // font-style: normal;

        h3 {
            font-style: normal;
            margin-bottom: 20px;
            font-family: "museo5";
        }

        p {
            font-style: normal;
            font-family: "museo1";
        }
    }

    .mision,
    .vision {
        color: $grey-dark;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: right;
        width: 90%;

        .contentInfo {
            margin: 30px;
            font-size: 23px;
            width: 60%;
            // border: 1px solid blue;
            h3 {
                color: $grey-dark;
                margin-bottom: 20px;
                font-family: "museo5";
            }
        }

        @media screen and (max-width: 428px) {
            flex-direction: column;
            margin: 0;
            padding: 0;
            width: 100%;
            .contentInfo {
                margin: 5px;
                font-size: 20px;
                width: 90%;
            }
        }
    }

    .vision {
        flex-direction: row-reverse;
        text-align: left;
        @media screen and (max-width: 428px) {
            flex-direction: column;
        }
    }

    .imgContent {
        width: 60%;
        // margin: 1px solid green;
        img {
            width: 90%;
            border-radius: 50px;
            -webkit-border-radius: 50px;
            -moz-border-radius: 50px;
            -o-border-radius: 50px;
        }

        @media screen and (max-width: 428px) {
            width: 100%;
        }
    }

    .lastConocenos {
        display: flex;
        // align-items: center;
        margin-top: 20px;
        margin-left: 50px;
        margin-right: 50px;
        justify-content: space-around;

        @media screen and (max-width: 428px) {
            margin-top: 20px;
            margin-left: 30px;
            margin-right: 0px;
            font-size: 18px;
            flex-direction: column;
        }
    }

    .principios {
        color: $grey-dark;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        margin-top: 20px;
        margin-left: 150px;
        font-size: larger;
        // justify-content: center;
        // align-items: center;
        text-align: center;

        h3 {
            margin-bottom: 20px;
            margin-top: 20px;
            font-family: "museo5";
        }

        ul li {
            // list-style: none;
            text-align: left;
        }

        li {
            margin-bottom: 15px;
        }

        @media screen and (max-width: 428px) {
            margin-top: 20px;
            margin-left: 0px;
            margin-right: 0px;
        }
    }

    .imgBrujula {
        margin-top: 30px;
        text-align: center;
        justify-content: center;
        font-family: "museo5";

        img {
            width: 70%;
        }

        @media screen and (max-width: 428px) {
            margin-left: -30px !important;
            img {
                width: 100%;
            }
        }
    }

    .fundador {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: $blue-dark;
        padding: 20px;
        margin-top: 30px;
        .img {
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 90%;
            img {
                width: 100%;
                padding: 0;
            }
            p {
                margin-top: -50px;
            }
        }

        .parrafo {
            color: white;
            padding-right: 50px;
            text-align: justify;
        }

        @media screen and (max-width: 428px) {
            flex-direction: column;
            padding: 20px;
            .parrafo {
                padding: 0;
                text-align: justify;
                margin-right: 0;
            }
        }
    }
}
