@import "./utilities/variables.scss";

.donaciones {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px, 50px, 20px, 50px;
    padding: 80px;
    h2 {
        text-align: center;
    }
}
