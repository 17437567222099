@import "./utilities/variables.scss";

.fullContent {
    color: $grey-dark;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // @media screen and (max-width: 960px) {
    //     height: 400px;
    // }

    // @media screen and (max-width: 428px) {
    //     height: 1000px;
    // }
}

.banner {
    display: block;
    width: 100%;
    height: 98vh;
    max-height: 98vh;
    min-height: 98vh;
    overflow: hidden;
    position: relative;
    padding: 0;
    padding-bottom: -20px;
    margin: -10px;
    // @media screen and (max-width: 960px) {
    //     position: relative;
    //     max-height: 450px;
    // }
    @media screen and (max-width: 428px) {
        height: 50vh;
        max-height: 50vh;
        min-height: 50vh;
        position: relative;
    }

    img {
        width: 100%;
        height: 100%;

        @media screen and (max-width: 428px) {
            height: 70%;
            margin-top: $header-height;
            margin-bottom: -$header-height;
        }
    }
}

.content {
    margin: 0;
    padding: 0 !important;
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .intro {
        // border: 1px solid blue;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: "museo2";
        font-style: italic;

        h2 {
            margin-bottom: 20px;
            font-size: 30px;
        }

        p {
            font-size: 25px;
        }

        h3 {
            font-size: 20px;
        }

        @media screen and (max-width: 428px) {
            width: 95% !important;
        }
    }

    .barrasColores {
        width: 100%;
        color: $blue-dark;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 20px;

        img {
            width: 140px;
            margin-left: 80px;
            @media screen and (max-width: 428px) {
                display: none;
            }
        }

        @media screen and (max-width: 428px) {
            // width: 93% !important;
            padding: 10px 50px;
            justify-content: center;
        }

        .text {
            display: flex;
            flex-direction: column;
            justify-content: left;
            text-align: left;
            align-items: flex-start;
            margin-left: 40px;
            margin-right: 100px;
            padding-right: 100px;
            font-size: 20px;
            @media screen and (max-width: 428px) {
                text-align: justify;
                padding-top: 10px;
                padding-bottom: 10px;
                margin-left: 0px;
                margin-right: 0px;
                padding-right: 0px;
                width: 100%;
            }
        }
    }

    .donaciones {
        background: rgb(109, 107, 176);
        background: linear-gradient(90deg, rgba(109, 107, 176, 1) 0%, rgba(255, 255, 255, 1) 100%);
    }

    .voluntariado {
        background: rgb(246, 197, 80);
        background: linear-gradient(90deg, rgba(246, 197, 80, 1) 0%, rgba(255, 255, 255, 1) 100%);
    }

    .material {
        background: rgb(166, 200, 88);
        background: linear-gradient(90deg, rgba(166, 200, 88, 1) 0%, rgba(255, 255, 255, 1) 100%);
    }

    .redes {
        background: rgb(230, 78, 85);
        background: linear-gradient(90deg, rgba(230, 78, 85, 1) 0%, rgba(255, 255, 255, 1) 100%);
    }

    .contactanos {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        h1 {
            margin-bottom: 30px;
        }

        h3 {
            justify-content: left;
            text-align: left;
        }
    }

    .elementosFila {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        @media screen and (max-width: 428px) {
            flex-direction: column;
        }
    }
}
