@import "./utilities/variables.scss";

.AyudanosHome {
    width: 100%;
    height: 380px;
    background-color: $green-light;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $purple-dark;

    .lluviaIconos {
        display: flex;
        justify-content: space-around;
        width: 70%;

        .icon {
            width: 60px;
            height: 60px;
            cursor: pointer;
            color: $white;
        }
    }

    h3 {
        font-size: 40px;
        position: relative;
        bottom: -50px;
        left: 0;
        margin: -5px;
        align-items: center;
        justify-content: center;
    }
    h2 {
        font-size: 25px;
        position: relative;
        padding-left: 80px;
        padding-right: 80px;
        bottom: -80px;
        left: 0;
        margin: 0px;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}
