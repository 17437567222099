@import "./utilities/variables.scss";

.educacion {
    color: $grey-dark;
    color: $grey-dark;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // @media screen and (max-width: 960px) {
    //     height: 400px;
    // }

    // @media screen and (max-width: 428px) {
    //     height: 210px;
    // }
}

.content {
    margin: 20px;
    padding: 20px;
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .intro {
        // border: 1px solid blue;
        padding: 20px;
        margin: 20px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: "museo2";
        font-style: italic;
        width: 80%;

        h2 {
            margin-bottom: 20px;
            font-size: 30px;
        }

        p {
            font-size: 25px;
        }
    }

    .imgContent {
        width: 60%;
        img {
            width: 90%;
            border-radius: 50px;
            -webkit-border-radius: 50px;
            -moz-border-radius: 50px;
            -o-border-radius: 50px;
        }

        @media screen and (max-width: 428px) {
            width: 100%;
            margin: 1px !important;
            padding-left: 30px;
        }
    }

    .textoFinal {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: right;
        align-items: center;
        height: 400px;
        font-size: 30px;
        margin-left: 80px;
        margin-right: 80px;
        margin-top: 50px;
        margin-bottom: 50px;
        .texto {
            width: 50%;
        }
        .imgContent {
            width: 50%;
            img {
                display: block;
                margin-left: 0;
                height: 400px;
            }
        }

        @media screen and (max-width: 428px) {
            flex-direction: column;
            width: 100%;
            height: 500px;
            font-size: 20px;
            margin-bottom: 50px;
            .texto {
                width: 100%;
                padding: 20px;
            }
            .imgContent {
                width: 130%;
                margin: 0;
                padding: 0;
                img {
                    width: 100%;
                    height: 500px;
                    margin-bottom: 30px;
                }
            }
        }
    }
}
