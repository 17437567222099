.home {
    position: inline-block;
    display: flex;
    flex-direction: column;
    // border: 1px solid green;

    // @media screen and (max-width: 960px) {
    //     height: 400px;
    // }

    // @media screen and (max-width: 428px) {
    //     height: 210px;
    // }
}