@import "./utilities/variables.scss";
.frase1 {
    width: 100%;
    background-color: $green-light;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 20px;
    padding-bottom: 0;
    // height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: justify;
    text-justify: inter-word;
    text-align: center;
    color: $purple-dark;
    span {
        font-family: "museo5";
    }
}

.spanTitle {
    letter-spacing: 4px;
}
