@import "./utilities/variables.scss";

//FUENTES
@font-face {
    font-family: "museo1";
    src: local("museo1"), url("./fonts/MuseoSansRounded100.otf");
}
@font-face {
    font-family: "museo2";
    src: local("museo2"), url("./fonts/MuseoSansRounded300.otf");
}
@font-face {
    font-family: "museo3";
    src: local("museo3"), url("./fonts/MuseoSansRounded500.otf");
}

@font-face {
    font-family: "museo4";
    src: local("museo4"), url("./fonts/MuseoSansRounded700.otf");
}

@font-face {
    font-family: "museo5";
    src: local("museo5"), url("./fonts/MuseoSansRounded900.otf");
}

@font-face {
    font-family: "museo6";
    src: local("museo6"), url("./fonts/MuseoSansRounded1000.otf");
}

//BASE

html {
    width: 100%;
    min-height: 100%;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "museo1";
    overflow-x: hidden;
    background-color: rgba(white, 0.2);
    color: $blue-dark;
    margin: 0;
    padding: 0;
    width: 100%;
    overflow: scroll;
    overflow-x: hidden;
}

h3 {
    font-family: "museo6";
}

@import "./header.scss";
@import "./hero.scss";
@import "./home.scss";
@import "./unete.scss";
@import "./inicioSaludo.scss";
@import "./ProyectosBotones.scss";
@import "./bannerParalax.scss";
@import "./AyudanosHome.scss";
@import "./footer.scss";
@import "./frase1.scss";
@import "./donaciones.scss";
@import "./Socialbtn.scss";

@import "./Conocenos.scss";
@import "./educacion.scss";
@import "./Comentarios.scss";
@import "./materiales.scss";

@import "./mapas.scss";
