@import "./utilities/variables.scss";

.socialMedia {
    display: block;
    position: fixed;
    top: 100px;
    right: 0;
    background-color: $green-dark;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px 0px 0px 10px;

    width: 50px;
    height: 185px;

    .imagenLogo {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    .icon {
        color: $white;
        width: 30px;
        height: 30px;
        cursor: pointer;
        padding: 2px;
        margin: 5px;
        transition: all 0.5 ease-in-out;
        &:hover {
            width: 33px;
            height: 33px;
            color: $blue-dark;
        }
        @media screen and (max-width: 1000px) {
            width: 20px;
            height: 20px;
        }
    }

    .facebook,
    .instagram,
    .twiter,
    .whataspp,
    .mail {
        cursor: pointer;
        margin: 0;

        &:hover {
            width: 43px;
            height: 43px;
        }

        @media screen and (max-width: 1000px) {
            width: 20px;
            height: 20px;
        }
    }

    @media screen and (max-width: 1000px) {
        display: none;
    }
}
