@import "./utilities/variables.scss";

.botones {
    width: 100%;
    // border: 1px solid red;
    // padding: 20px;
    margin: 1px 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    // background-color: rgba($grey-light, .2) ;

    @media screen and (max-width: 428px) {
        flex-direction: row;
        align-items: flex-start;
    }

    .linkCircleButton {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        text-decoration: none;
        color: $grey-dark;
        font-size: 15px;
        font-weight: bold;
        font-family: "museo4";
        @media screen and (max-width: 428px) {
            width: 8rem;
            height: 10rem;
            padding: 0;
            justify-content: top;
            align-items: center;
            text-align: center;
            align-self: stretch;
        }
    }

    .circleButton {
        padding: 20px;
        border-radius: 50%;
        width: 6rem;
        height: 6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "museo1";
        font-size: 20px;
        margin: 0 50px;

        img {
            width: 90%;
        }

        @media screen and (max-width: 428px) {
            width: 4rem;
            height: 4rem;
        }
    }

    h2 {
        @media screen and (max-width: 428px) {
            font: 1em sans-serif;
        }
    }
    .circleButton.voluntarios {
        background-color: $purple-dark;
    }

    .circleButton.proyectos {
        background-color: $yellow-dark;
    }
    .circleButton.juegos {
        // background-color: $green-dark;
        background-color: $green-dark;
    }
}
