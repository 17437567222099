@import "./utilities/variables.scss";

.bannerParalax {
    display: flex;
    flex-direction: row;
    width: 100%;
    // border: 1px solid red;
    // background-image: url("./imgenes/");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(29, 28, 29, 0.7);
    min-height: $heigth-paralax;
    text-align: center;
    justify-content: center;

    .contentParalax {
        margin: 20px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        // background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0), rgba(166, 200, 88, 0.4));
        background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0), rgba(237, 176, 64, 0.8));
        width: 40%;
        height: $heigth-paralax;
        color: $white;
        font-weight: bold;
        font-size: 15px;
        letter-spacing: 2px;
        justify-content: center;
        @media screen and (max-width: 428px) {
            width: 100%;
            font-size: 15px;
            margin: 0;
            padding: 0;
        }
    }

    @media screen and (max-width: 428px) {
        flex-direction: column;
    }

    .tituloBanner {
        margin-bottom: 20px;
        @media screen and (max-width: 428px) {
        }
    }

    .fraseBanner {
        white-space: pre-line;
    }
}
