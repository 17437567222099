@import "./utilities/variables.scss";

.saludo {
    // border: 1px solid $blue-dark;

    // color: $yellow-light;
    color: $grey-dark;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 500px;
    // position: relative;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 428px) {
        padding: 0;
        font-size: 20px;
        height: 900px;
    }

    .backgroundimageSaludo {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }

    .contenedorDoble {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media screen and (max-width: 428px) {
            flex-direction: column;
        }
    }

    .textos {
        justify-content: center;
        display: flex;
        flex-direction: column;
        text-align: left;
        font-size: larger;
        width: 50%;
        margin-left: 80px;
        padding-right: 0px;

        @media screen and (max-width: 428px) {
            width: 100%;
            margin-left: 10px;
            padding-right: 20px;
            text-align: justify;
        }
    }

    .imagen {
        // rotate: 10deg;
        // width: 300px;
        height: 500px;
        width: 50%;
        z-index: -10;
        margin-right: 50px;
        @media screen and (max-width: 428px) {
            height: 500px;
            width: 100%;
            margin-right: 0px;
        }
    }

    .titleDoble {
        margin-bottom: 30px;
        // margin-top: -80px;
        text-align: left;
        z-index: 10;
        @media screen and (max-width: 1000px) {
            font-size: 30px;
            z-index: 0;
        }
        span {
            font-family: "museo5";
            font-weight: bold;
        }
    }

    p {
        z-index: 10;
        // font-size: 30px;
        // margin: 0 10px;
        // border: 1px solid red;
        @media screen and (max-width: 1000px) {
            font-size: 20px;
            margin: 0 10px;
            z-index: 0;
        }
    }

    .endText {
        z-index: 10;
        font-family: "museo5";
        align-items: center;
        text-align: center;
        margin: 0 50px;
        letter-spacing: 2px;
        margin-top: 80px;
        // border: 1px solid red;
        @media screen and (max-width: 1000px) {
            font-size: 20px;
            margin: 0 10px;
            z-index: 0;
        }
    }

    @media screen and (max-width: 1000px) {
        // margin-top: 50px;
    }
}
