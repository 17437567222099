@import "./utilities/variables.scss";

.container {
    position: absolute;
    width: 100%;
    height: $header-height;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    color: $white;
    top: 0;
    /* background-color: var(--blue-light); */
    /* background-image: linear-gradient(to bottom, var(--blue-light) , rgba(0,0,0,0) 100%); */
    // background: linear-gradient(to bottom, rgba(52, 152, 219, 0.7), rgba(0, 0, 0, 0));

    @media screen and (max-width: 1000px) {
        position: inherit;
        background: $green-dark;
        height: 100%;
    }
}

.wrapper {
    position: fixed;
    width: 100%;
    // background: linear-gradient(to bottom, $green-light, rgba(0, 0, 0, 0));
    background: linear-gradient(to bottom, $white 73%, rgba(0, 0, 0, 0));
    // max-width: 1300px;
    height: $header-height;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto;

    .background {
        position: absolute;
        width: 100%;
        // max-width: 1300px;
        height: 150px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: auto;
        height: $header-height;
        background-color: rgba(0, 0, 0, 0);
        transition: all 0.5s ease-in;
        z-index: 1;
        @media screen and (max-width: 1000px) {
            background-color: white;
        }
    }

    @media screen and (max-width: 1000px) {
        z-index: 1000;
        height: 0;
    }
}

.wrapper.fullwrapper {
    .background {
        background-color: $white;
        transition: all 0.5s ease-in;
        height: calc(#{$header-height} - 30px);
    }
}

.logoContainer {
    margin-left: 2rem;
    display: flex;
    align-items: center;
    font-family: "museo3";
    z-index: 100;
    padding-top: -5px;
    margin-top: -10px;
    img {
        height: calc(#{$header-height} - 50px);
        transition: all 0.2s ease-in-out;
        &:hover {
            /* transform: scale(1.7) rotate(-3deg) translateX(5px);  */
            cursor: pointer;
        }
    }
}

.menu {
    height: 100%;
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin-top: 2px;
    margin-right: 50px;
    z-index: 20;

    @media screen and (max-width: 1000px) {
        background-color: $green-dark;
        position: absolute;
        /* top: 70px; */
        // top: ${({ open }) => (open ? "0" : "-100%")};
        top: -1000%;
        left: 0;
        //importante para abrir y cerrar el menu
        width: 110%;
        height: 90vh;
        margin-top: 15px;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        transition: 0.5s all ease;
    }
}

.menu.showMobile {
    @media screen and (max-width: 1000px) {
        background-color: $white;
        position: absolute;
        /* top: 70px; */
        top: -20%;
        z-index: 0;
        left: 0;
        //importante para abrir y cerrar el menu
        width: 100%;
        height: 90vh;
        margin-top: 15px;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        transition: 0.5s all ease;
    }
}

.menuItem {
    height: 100%;

    @media screen and (max-width: 1000px) {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.menuItemLink {
    // color: $blue-dark !important;
    color: $grey-dark !important;
    z-index: 1000;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35%;
    padding: 8px 15px;
    margin: 20px 10px;
    font-family: "museo2";
    font-size: 1.5rem;
    font-weight: 600;
    border-radius: 20px;
    cursor: pointer;

    background-image: url("./img/backgroundeffect.png");
    background-repeat: repeat-x;
    background-position: 0 -100%;
    transition: 1.5s ease;

    &:hover {
        color: #fff !important;
        transition: 0.5 all ease;
        background-position: center;
        color: $white;

        div {
            svg {
                fill: $grey-dark;
            }
        }
    }

    div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            display: none;
            fill: $yellow-dark;
            margin-right: 0.5rem;
            width: 50px;
        }
    }

    @media screen and (max-width: 1000px) {
        width: 100%;

        div {
            width: 100%;
            justify-content: left;

            svg {
                display: flex;
                width: 10%;
            }
        }
    }
}

.menuItemLink.active {
    color: #fff !important;
    background-position: center;
    color: $white;

    @media screen and (max-width: 1000px) {
        width: 100%;
        height: 100%;
        div {
            svg {
                fill: $white;
            }
        }
    }
}

.mobileIcon {
    display: none;
    z-index: 9999;

    @media screen and (max-width: 1000px) {
        display: flex;
        align-items: center;
        cursor: pointer;

        svg {
            fill: #333;
            margin-right: 0.5rem;
        }
    }
}

.worldIcon {
    margin-top: 20px;
    color: $purple-dark;
    cursor: pointer;
    opacity: 1;
    @media screen and (max-width: 1000px) {
        opacity: 0;
    }
}

.menuItem.leng {
    position: absolute;
    opacity: 0;
    top: 30px;
    right: -100px;
    height: 30px;
    transition: all 0.5s ease-in-out;
    z-index: 100;
    @media screen and (max-width: 1000px) {
        opacity: 1;
        position: relative;
        display: inline-block;
        right: 0px;
        margin: 0 auto;
        text-align: center;
    }
}

.menuItem.leng.menuLeng {
    display: block;
    position: absolute;
    opacity: 1;
    top: 60px;
    right: 10px;
    height: 30px;
    transition: all 0.5s ease-in-out;
}

.world {
    margin-top: 30px;
    color: $purple-dark;
    cursor: pointer;
    border-radius: 60%;
    height: 30px;
    @media screen and (max-width: 1000px) {
        display: none;
    }
}

.lang {
    border-radius: 60%;
    height: 30px;
    width: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: none;
    margin-left: 5px;
    &:hover {
        cursor: pointer;
    }
}

.lang.es {
    background-image: url("./img/icons/Flag-of-Bolivia.svg");
}
.lang.en {
    background-image: url("./img/icons/Flag_of_the_United_States.svg");
}
.lang.fr {
    background-image: url("./img/icons/frenchflagframed.svg");
}
